import React, { useState, useEffect } from 'react';
import './App.css';
import { API } from 'aws-amplify';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import { listCategories } from './graphql/queries';
import { createCategory as createCategoryMutation, deleteCategory as deleteCategoryMutation } from './graphql/mutations';

const initialFormState = { name: '', description: '' }

function App() {
	const [categories, setCategories] = useState([]);
	const [formData, setFormData] = useState(initialFormState);

	useEffect(() => {
		fetchCategories();
	}, []);

	async function fetchCategories() {
		const apiData = await API.graphql({ query: listCategories });
		setCategories(apiData.data.listCategories.items);
	}

	async function createCategory() {
		if (!formData.name || !formData.description) return;
		await API.graphql({ query: createCategoryMutation, variables: { input: formData } });
		setCategories([ ...categories, formData ]);
		setFormData(initialFormState);
	}

	async function deleteCategory({ id }) {
		const newCategoriesArray = categories.filter(category => category.id !== id);
		setCategories(newCategoriesArray);
		await API.graphql({ query: deleteCategoryMutation, variables: { input: { id } }});
	}

	return (
		<div className="App">
			<h1>My Categories App</h1>
			<input
				onChange={e => setFormData({ ...formData, 'name': e.target.value})}
				placeholder="Category name"
				value={formData.name}
			/>
			<input
				onChange={e => setFormData({ ...formData, 'description': e.target.value})}
				placeholder="Category description"
				value={formData.description}
			/>
			<button onClick={createCategory}>Create Category</button>
			<div style={{marginBottom: 30}}>
				{
					categories.map(category => (
						<div key={category.id || category.name}>
							<h2>{category.name}</h2>
							<p>{category.description}</p>
							<button onClick={() => deleteCategory(category)}>Delete category</button>
						</div>
					))
				}
			</div>
			<AmplifySignOut />
		</div>
	);
}

export default withAuthenticator(App);